import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebsocketInit } from '@app/core/models/connection-status';
import { WsService } from '@app/core/services/ws.service';
import { NOTIFICATION_TYPE, SMSMessage, STREAM_UPDATE_TYPE, UnreadCount } from '@app/sms/models/sms.models';
import { environment } from '@environment/environment';
import { BehaviorSubject, catchError, combineLatest, filter, from, map, mergeMap, of, switchMap, toArray } from 'rxjs';

import { SMSService } from './sms.service';

/**
 * Handles the logic managing "unread" and "read" messages which
 * includes:
 * - Marking messages as "read"
 *
 * @export
 * @class SMSUnreadMessageService
 */
@Injectable({
  providedIn: 'root',
})
export class SMSUnreadMessageService implements WebsocketInit {
  private readonly unreadCountSubject = new BehaviorSubject<UnreadCount>({});
  public readonly unreadCount$ = this.unreadCountSubject.asObservable();

  private readonly unreadPhoneNumberCountSubject: BehaviorSubject<UnreadCount> = new BehaviorSubject<UnreadCount>({});
  public readonly unreadPhoneNumberCount$ = this.unreadPhoneNumberCountSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private webSocketService: WsService,
    private smsService: SMSService
  ) {
    // Update whenever our conversation list _or_ localNumbers gets updated
    combineLatest([this.smsService.data$, this.smsService.localNumbers$]).subscribe(([conversations]) => {
      // Iterate over each conversation and update the corresponding unread count for that number. Conversation ids are
      // auto-incremented integers so we need to specially format phone numbers in this dictionary to avoid collisions.
      const count: UnreadCount = {};
      for (const conversation of conversations) {
        count[conversation.conversationId] = conversation.unreadMessageCount;
      }
      this.unreadCountSubject.next({ ...this.unreadCountSubject.value, ...count });
    });

    // Any time the unread count changes, also recalculate the unread count for our phone numbers
    this.unreadCount$
      .pipe(
        map((unreadCount) => ({ unreadCount, conversationIds: Object.keys(unreadCount) })),
        switchMap(({ unreadCount, conversationIds }) =>
          from(conversationIds).pipe(
            mergeMap((id) => this.smsService.getCachedConversationWithId(id)),
            filter((result): result is NonNullable<typeof result> => result !== null), // Type guard to filter out null results
            toArray(), // Collect all non-null results into a single array
            map((results) => ({ unreadCount, conversations: results })) // Combine unreadCount and results
          )
        )
      )
      .subscribe(({ unreadCount, conversations }) => {
        const phoneCount: UnreadCount = {};
        for (const conversation of conversations) {
          phoneCount[conversation.local] =
            (phoneCount[conversation.local] || 0) + (unreadCount[conversation.conversationId] || 0);
        }
        this.unreadPhoneNumberCountSubject.next({ ...this.unreadPhoneNumberCountSubject.value, ...phoneCount });
      });
  }

  bindSocketEvents() {
    this.webSocketService.socket.on('SMSConversationReadMarkerUpdated', (data: { conversationId: string }) => {
      this.setUnreadCount(data.conversationId, 0);
    });

    this.webSocketService.socket.on('SMSMessageReceived', (message: SMSMessage) => {
      // Filter out any notification messages that aren't streamupdate type. Standard messages won't have the `notification`
      // prop set and when it is set, only stream updates should be processed.
      if (
        message.type === NOTIFICATION_TYPE ||
        (message.notification?.type && message.notification.type !== STREAM_UPDATE_TYPE)
      ) {
        return;
      }

      // When a message is received on the socket, check it see if we're currently rendering that conversation.
      // If not, increment the unread count.
      // TODO: We may want to eventually make this more sophisticated so if the conversationId _does_ match the current
      // conversation, we still may increment the count if the user isn't scrolled to the bottom of the conversation.
      if (message.conversationId && document.location.href.search(message.conversationId) === -1) {
        this.incrementUnreadCount(message.conversationId);
      }
    });

    this.webSocketService.socket.on('SMSMessageSent', (data: { conversationId: string }) => {
      this.updateReadMarker(data.conversationId);
    });
  }

  public getUnreadCount(conversationId: string): number {
    return this.unreadCountSubject.getValue()[conversationId] || 0;
  }

  private incrementUnreadCount(conversationId: string) {
    const count = this.getUnreadCount(conversationId) + 1;
    this.setUnreadCount(conversationId, count);
  }

  private setUnreadCount(conversationId: string, count: number) {
    const unreadCount = this.unreadCountSubject.getValue();
    unreadCount[conversationId] = count;

    // Any time the unread count is updated manually we should also update the value on the conversation
    // in case it is read
    this.smsService.updateConversationById(conversationId, { unreadMessageCount: count }).finally(() => {
      this.unreadCountSubject.next({ ...unreadCount });
    });
  }

  public updateReadMarker(conversationId: string) {
    // Passing `null` for body will update the read marker on the server to the latest message's timestamp
    return this.httpClient
      .put(`${environment.messageHubGateway}/sms/conversations/${conversationId}/read-marker`, {})
      .pipe(
        catchError(() => {
          return of();
        })
      )
      .subscribe(() => {
        this.setUnreadCount(conversationId, 0);
      });
  }

  public updateAllConversationsReadMarker() {
    return this.httpClient.put(`${environment.messageHubGateway}/sms/messages/read-marker`, {}).subscribe();
  }
}
